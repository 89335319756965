import React from 'react'
import './css/footer.css'

const Footer = () => {
  return (
    <div>
      <footer>
        <p>copyright &copy; EarthStrix</p>
      </footer>
    </div>
  );
}

export default Footer